export const columns = [
  {
    title: '任务标题',
    dataIndex: 'taskTitle',
    key: 'taskTitle',
    align:'center',
    ellipsis:true,
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'name'}
},
{
    title: '关联订单号',
    dataIndex: 'orderCode',
    key: 'orderCode',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'orderCode'}
},
// {
//     title: '经销商ID',
//     dataIndex: 'customerId',
//     key: 'customerId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
{
    title: '经销商名称',
    dataIndex: 'customerName',
    key: 'customerName',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'customerName'}
},

// {
//     title: '是否删除',
//     dataIndex: 'flagDel',
//     key: 'flagDel',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '创建人',
//     dataIndex: 'createUser',
//     key: 'createUser',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '创建人ID',
//     dataIndex: 'createUsetId',
//     key: 'createUsetId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:200,
    align:'center',
    ellipsis:true
},
// {
//     title: '修改人',
//     dataIndex: 'modifyUser',
//     key: 'modifyUser',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '修改人ID',
//     dataIndex: 'modifyUserId',
//     key: 'modifyUserId',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '修改时间',
//     dataIndex: 'modifyTime',
//     key: 'modifyTime',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '备注',
//     dataIndex: 'remark',
//     key: 'remark',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
// {
//     title: '版本号',
//     dataIndex: 'version',
//     key: 'version',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
]

export const Alarmcolumns = [
    {
        title: '经销商名',
        dataIndex: 'customerName',
        key: 'customerName',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        width: 200,
        fixed: 'left',
        scopedSlots: { customRender: 'customerName'}
    },
    {
      title: '要货单',
      dataIndex: 'orderCode',
      key: 'orderCode',
      /*width:150,*/
      align:'center',
      ellipsis:true
  },
  {
      title: '扫码次数',
      dataIndex: 'scanningCount',
      key: 'scanningCount',
      /*width:150,*/
      align:'center',
      ellipsis:true
  },
  {
      title: '异常次数',
      dataIndex: 'abnormalCount',
      key: 'abnormalCount',
      /*width:150,*/
      align:'center',
      ellipsis:true,
      scopedSlots: { customRender: 'abnormalCount'}
    },
  {
      title: '订单地址',
      dataIndex: 'address',
      key: 'address',
      /*width:150,*/
      align:'center',
      ellipsis:true
  },
]

export const Recordcolumns = [
    {
        title: '微信昵称',
        dataIndex: 'scanningUserName',
        key: 'scanningUserName',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        width: 200,
        fixed: 'left',
        scopedSlots: { customRender: 'scanningUserName'}
  },
  {
      title: '微信头像',
      dataIndex: 'scanningHeadPicture',
      key: 'scanningHeadPicture',
      /*width:150,*/
      align:'center',
      ellipsis:true,
      scopedSlots: { customRender: 'scanningHeadPicture'}
    },
  {
      title: '扫码地址',
      dataIndex: 'scanningAddress',
      key: 'scanningAddress',
      /*width:150,*/
      align:'center',
      ellipsis:true
  },
//   {
//       title: '订单地址',
//       dataIndex: 'customerName',
//       key: 'customerName',
//       /*width:150,*/
//       align:'center',
//       ellipsis:true
//   },
  {
      title: '操作时间',
      dataIndex: 'scanningTime',
      key: 'scanningTime',
      /*width:150,*/
      align:'center',
      width: 200,
      ellipsis:true
  },
]